import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import ContentListComponent from '../contentListing/contentListComponent'
import {
  ARTIST_LISTING,
  CONTENT_FILTERING,
  SYSTEM_METADATA,
  VENUE_LISTING,
} from '../../utils/apiUrls'
import { AppContext } from '../../context/app'
import { useParams } from 'react-router'
import { isEmpty, PAGE_SIZE } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import '../../components/template/template.scss'
import RowingHeroSection from '../../components/template/rosemary/rowingHeroSection'
import AsymmetricFlow from '../../components/template/rosemary/asymmetricFlow'
import PromotionalLead from '../../components/template/rosemary/promotionalLead'
import PassimCarve from '../../components/template/rosemary/passimCarve'
import InventorySlide from '../../components/template/rosemary/inventorySlide'
import ClassyBanners from '../../components/template/fitness/classyBanners'
import NarrowAdmonition from '../../components/template/fitness/narrowAdmonition'
import DefinityCards from '../../components/template/fitness/definityCards'
import FineTunedGallery from '../../components/template/fitness/fineTunedGallery'
import FlyerIntro from '../../components/template/fitness/flyerIntro'
import MementoSlides from '../../components/template/fitness/mementoSlides'
import WedgedInfo from '../../components/template/fitness/wedgedInfo'
import DecomposedBanners from '../../components/template/gaming/decomposedBanners'
import ExtensiveCarouselRendition from '../../components/template/gaming/extensiveCarouselRendition'
import AdNCarousel from '../../components/template/gaming/adNCarousel'
import ExtensiveCarouselSimple from '../../components/template/gaming/extensiveCarouselSimple'
import TripleCardCarousel from '../../components/template/gaming/tripleCardCarousel'
import BobbingCards from '../../components/template/gaming/bobbingCards'
import DefaultBanner from '../../components/template/default/defaultBanner'
import DefaultSwimlane from '../../components/template/default/defaultSwimlane'
import DefaultArtistSwimlane from '../../components/template/default/defaultArtistSwimlane'
import DefaultVenueSwimlane from '../../components/template/default/defaultVenueSwimlane'
import PassimCarvePlayer from '../../components/template/rosemary/passimCarvePlayer'
import ClassBannerPlayer from '../../components/template/fitness/classBannerPlayer'
import DefaultBannerPlayer from '../../components/template/default/defaultBannerPlayer'
import DecomposedBannersPlayer from '../../components/template/gaming/decomposedBannersPlayer'
import AdNCarouselPlayer from '../../components/template/gaming/adNCarouselPlayer'
import NewDefaultSwimlane from '../../components/template/default/newDefaultSwimlane'
import AudioSwimlane from '../../components/template/default/audioSwimlane'
import TopTenSwimlane from '../../components/template/default/topTenSwimlane'
import ArticleText from '../../components/template/default/articleText'
import ArticleImage from '../../components/template/default/articleImage'
import { withTheme } from 'theming'
import FiltersLogo from '../../assets/imgComponents/filtersLogo'
import FiltersMobileIcon from '../../assets/filter-mobile-icon.svg'
import FiltersDropDown from './filtersDropDown'
import { createSystemDefinedFilter } from '../../utils/filterPayloadCreator'
import FiltersCheck from '../../assets/imgComponents/filtersCheck'
import CategorySlider from '../../components/template/default/categorySlider'
import ContentGrid from '../../components/template/default/contentGrid'

let totalRecords = 0
let currentPage = 1
let apiHit = false
let orderBy = 'modified_date'
let reverse = false

const SystemMetadata = ({ theme }) => {
  const categoryId = useParams().categoryId

  const { t } = useTranslation()
  const { setLoader } = useContext(AppContext)

  const [dataList, setDataList] = useState([])
  const [categoryInfo, setCategoryInfo] = useState({})
  const [componentData, setComponentData] = useState([])
  const [filterDropdown, setFilterDropdown] = useState(false) // for filter dropdown

  const [filters, setFilters] = useState([])
  const [filterPayloadObj, setFilterPayloadObj] = useState({
    system_defined: [
      {
        key: 'category',
        name: 'category',
        type: 'system_defined',
        filter_type: 'AND',
        operator: 'eq',
        values: [categoryId],
      },
    ],
  })
  const [artistData, setArtistData] = useState([])
  const [artistFilter, setArtistFilter] = useState([])
  const [venueData, setVenueData] = useState([])
  const [venueFilter, setVenueFilter] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [sortByFilter, setSortByFilter] = useState('')

  const [{ data: artistList, loading: artistListLoading }] = useAxios({
    url: ARTIST_LISTING,
    method: 'get',
    params: {
      page_size: 1000,
      page: 1,
    },
  })

  const [{ data: venueList, loading: venueListLoading }] = useAxios({
    url: VENUE_LISTING,
    method: 'get',
    params: {
      page_size: 1000,
      page: 1,
    },
  })

  const [{ data: categoryRes, loading: categoryLoading }, categoryCall] = useAxios(
    {
      url: `${SYSTEM_METADATA}/${categoryId}`,
      method: 'get',
    },
    { manual: true },
  )

  const [{ data: contentList, loading: contentListLoading }, contentFilterCall] = useAxios(
    {
      url: CONTENT_FILTERING,
      method: 'post',
    },
    { manual: true },
  )

  const filterIntialValue = [
    {
      title: 'Artists',
      type: 'checkbox',
      checkedState: '',
      options: artistData.map((info) => {
        return { name: info.name, value: info._id }
      }),
      onSelect: (checked, value) => {
        onFilterChange(0, setArtistFilter, checked, value)
      },
    },
    {
      title: 'Venue',
      type: 'checkbox',
      checkedState: '',
      options: venueData.map((info) => {
        return { name: info.name, value: info._id }
      }),
      onSelect: (checked, value) => {
        onFilterChange(1, setVenueFilter, checked, value)
      },
    },
    {
      title: 'Sort by',
      type: 'radio',
      checkedState: '',
      options: [
        { name: 'Upload date', value: 'publish_time' },
        { name: 'Name', value: 'title' },
      ],
      onSelect: (value) => {
        onSortFilterChange(value)
      },
    },
  ]

  useEffect(() => {
    categoryCall()
  }, [categoryId])

  useEffect(() => {
    if (artistList && artistList.success) {
      setArtistData(artistList?.data)
    }
  }, [artistList])

  useEffect(() => {
    if (venueList && venueList.success) {
      setVenueData(venueList.data)
    }
  }, [venueList])

  useEffect(() => {
    if (!isEmpty(artistData) && !isEmpty(venueData)) {
      setFilters(filterIntialValue)
    }
  }, [artistData, venueData])

  useEffect(() => {
    let tempFilterObj = {
      system_defined: [
        {
          key: 'artists',
          name: 'Artist',
          filter_type: 'AND',
          operator: 'eq',
          values: artistFilter,
        },
        {
          key: 'venue',
          name: 'Venue',
          filter_type: 'AND',
          operator: 'eq',
          values: venueFilter,
        },
        {
          key: 'category',
          name: 'category',
          type: 'system_defined',
          filter_type: 'AND',
          operator: 'eq',
          values: [categoryId],
        },
      ],
    }

    setFilterPayloadObj({ ...tempFilterObj })
  }, [artistFilter, venueFilter])

  const onSortFilterChange = (value) => {
    setSortByFilter(value === '' ? '' : value)
    handleFilterChange(2, value)
  }

  const onFilterChange = (index, setStateFunc, checked, value) => {
    if (checked) {
      setStateFunc((prev) => {
        handleFilterChange(index, [...prev, value])
        return [...prev, value]
      })
    } else {
      setStateFunc((prev) => {
        handleFilterChange(
          index,
          [...prev].filter((type) => type !== value),
        )
        return [...prev].filter((type) => type !== value)
      })
    }
  }

  const handleFilterChange = (index, value) => {
    setFilters((prev) => {
      prev[index] = { ...prev[index], checkedState: value }
      return [...prev]
    })
  }

  const applyFilter = () => {
    currentPage = 1
    hitAPI(1, 'modified_date', reverse, { filters: filterPayloadObj })
  }

  const resetFilters = () => {
    setFilters(filterIntialValue)
    setArtistFilter([])
    setVenueFilter([])
    setSortByFilter('')
    currentPage = 1
    hitAPI(1, 'modified_date', reverse, {
      filters: {
        system_defined: [
          {
            key: 'category',
            name: 'category',
            type: 'system_defined',
            filter_type: 'AND',
            operator: 'eq',
            values: [categoryId],
          },
        ],
      },
    })
  }

  const hitAPI = (page, orderBy, reverse, payload) => {
    if (!isEmpty(payload)) {
      payload['page_size'] = PAGE_SIZE
      payload['page'] = page
      payload['order_by'] = sortByFilter ? sortByFilter : orderBy ? orderBy : 'modified_date'
      payload['reverse'] = reverse ? reverse : false
      apiHit = true
      contentFilterCall({
        data: payload,
      })
    }
  }

  useEffect(() => {
    if (!isEmpty(categoryInfo)) {
      let categoryObj = categoryInfo

      if (categoryObj) {
        orderBy = categoryObj?.order_by ? categoryObj?.order_by : 'modified_date'
        reverse = categoryObj?.reverse ? categoryObj?.reverse : false
        hitAPI(
          1,
          categoryObj?.order_by,
          categoryObj?.reverse,
          createSystemDefinedFilter('category', [categoryId]),
        )
        currentPage = 1
        setDataList([])
        totalRecords = 0
      }
    }
  }, [categoryId, categoryInfo])

  useEffect(() => {
    if (categoryRes && categoryRes.success) {
      setCategoryInfo(categoryRes.data)
      setComponentData(categoryRes?.data.components)
    }
  }, [categoryRes])

  useEffect(() => {
    if (contentList && contentList.success) {
      setDataList((dataList) =>
        currentPage > 1 ? [...dataList, ...contentList.data] : [...contentList.data],
      )
      totalRecords = contentList.total
      apiHit = false
    }
  }, [contentList])

  useEffect(() => {
    setLoader(contentListLoading || categoryLoading || venueListLoading || artistListLoading)
  }, [contentListLoading, categoryLoading, venueListLoading, artistListLoading])

  useEffect(() => {
    window.addEventListener('scroll', infiniteScroll)
    return function cleanupListener() {
      window.removeEventListener('scroll', infiniteScroll)
    }
  }, [categoryId])

  const infiniteScroll = () => {
    // End of the document reached?
    if (
      window.innerHeight + document.documentElement.scrollTop >
      0.8 * document.documentElement.offsetHeight
    ) {
      if (!apiHit && totalRecords > currentPage * PAGE_SIZE) {
        apiHit = true
        currentPage += 1
        hitAPI(currentPage, orderBy, reverse, { filters: filterPayloadObj })
      }
    }
  }

  const getComponent = (key, data) => {
    if (!key) return
    let componentProps = {
      componentData: data,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
      onSwap: () => {},
      onDelete: () => {},
      onCopy: () => {},
      onAdd: () => {},
    }
    let componentObj = {
      rowingHeroSection: <RowingHeroSection {...componentProps} />,
      asymmetricFlow: <AsymmetricFlow {...componentProps} />,
      promotionalLead: <PromotionalLead {...componentProps} />,
      passimCarve: <PassimCarve {...componentProps} />,
      passimCarvePlayer: <PassimCarvePlayer {...componentProps} />,
      inventorySlide: <InventorySlide {...componentProps} />,
      classyBanners: <ClassyBanners {...componentProps} />,
      classyBannersPlayer: <ClassBannerPlayer {...componentProps} />,
      categoryBanners: <CategorySlider {...componentProps} />,
      narrowAdmonition: <NarrowAdmonition {...componentProps} />,
      definityCards: <DefinityCards {...componentProps} />,
      fineTunedGallery: <FineTunedGallery {...componentProps} />,
      flyerIntro: <FlyerIntro {...componentProps} />,
      mementoSlides: <MementoSlides {...componentProps} />,
      wedgedInfo: <WedgedInfo {...componentProps} />,
      decomposedBanners: <DecomposedBanners {...componentProps} />,
      decomposedBannersPlayer: <DecomposedBannersPlayer {...componentProps} />,
      extensiveCarouselRendition: <ExtensiveCarouselRendition {...componentProps} />,
      adNCarousel: <AdNCarousel {...componentProps} />,
      adNCarouselPlayer: <AdNCarouselPlayer {...componentProps} />,
      extensiveCarousel: <ExtensiveCarouselSimple {...componentProps} />,
      tripleCardCarousel: <TripleCardCarousel {...componentProps} />,
      bobbingCards: <BobbingCards {...componentProps} />,
      defaultBanner: <DefaultBanner {...componentProps} />,
      defaultBannerPlayer: <DefaultBannerPlayer {...componentProps} />,
      defaultSwimlane: <DefaultSwimlane {...componentProps} />,
      newDefaultSwimlane: <NewDefaultSwimlane {...componentProps} />,
      audioSwimlane: <AudioSwimlane {...componentProps} />,
      artistSwimlane: <DefaultArtistSwimlane {...componentProps} />,
      venueSwimlane: <DefaultVenueSwimlane {...componentProps} />,
      topTenSwimlane: <TopTenSwimlane {...componentProps} />,
      articleText: <ArticleText {...componentProps} />,
      articleImage: <ArticleImage {...componentProps} />,
      contentGrid: <ContentGrid {...componentProps} />,
    }
    return componentObj[key]
  }
  return (
    <>
      <div className="z-0">
        {!isEmpty(componentData) &&
          componentData.map((info, index) => getComponent(info?.data?.component_key, info?.data))}
      </div>
      <div className=" w-full px-10 md:px-16 lg:px-20 mt-5 xl:mt-10 flex justify-center z-40 relative">
        <div
          style={{
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            background: '#0f172195',
            color: '#00ffff',
          }}
          className="w-full px-6 flex justify-between items-center py-[9px] xl:relative"
        >
          <p className="w-3/4 line-clamp-1 text-sm xl:text-[22px] font-bold pb-2">
            {categoryInfo?.name || ''}
          </p>
          <div className="hidden xl:w-1/5 xl:flex justify-between items-center text-sm">
            <p className="mb-0">
              {`'${totalRecords}'`} {t('results')}
            </p>
            <p className="mb-0 underline cursor-pointer" onClick={resetFilters}>
              {t('clear_all')}
            </p>
            <div
              style={{ border: `1px solid #00ffff` }}
              className="cursor-pointer rounded-full flex items-center py-2 pl-4 pr-5 relative"
              onClick={() => setFilterDropdown(!filterDropdown)}
            >
              <div>
                <FiltersLogo />
              </div>
              <p className="mb-0 ml-3">{t('filters')}</p>
              {isFilterApplied && (
                <div style={{ right: '-0.4rem' }} className="absolute">
                  <FiltersCheck />
                </div>
              )}
            </div>
          </div>
          <div
            className="relative block xl:hidden"
            onClick={() => setFilterDropdown(!filterDropdown)}
          >
            <img src={FiltersMobileIcon} alt="FiltersMobileIcon" />
            {isFilterApplied && (
              <div
                style={{ right: '-5.5px', width: '11px', height: '11px', top: '8.5px' }}
                className="absolute"
              >
                <FiltersCheck styles="w-full h-full" />
              </div>
            )}
          </div>

          <FiltersDropDown
            filterDropdown={filterDropdown}
            setFilterDropdown={setFilterDropdown}
            filters={filters}
            onApply={applyFilter}
            onClearAll={resetFilters}
            setIsFilterApplied={setIsFilterApplied}
          />
        </div>
      </div>
      <ContentListComponent
        title={''}
        data={dataList}
        noContentText={contentListLoading || categoryLoading ? '' : t('no_content_category')}
      />
    </>
  )
}

export default withTheme(SystemMetadata)
